.mv-bar.mv-ui {
	all: initial;
	visibility: inherit;
	display: flex;
	align-items: center;
	color: white;
	padding: 0 .5em;
	background: hsla(0,0%,10%,.7);
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.5;
	position: sticky;

	@supports (position: sticky) {
		z-index: 2;
		top: 5px;
	}

	a {
		text-decoration: none;
	}

	&.mv-fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10000; // this is wrong, but must override other wrong things :(
	}

	&:not(.mv-fixed) {
		border-radius: .3em;
		margin-bottom: 1em;
	}

	button, .mv-button {
		all: unset;
		font-weight: bold;
		cursor: pointer;
		background: transparent;
		color: inherit;

		&:focus {
			background: hsla(0,0%,100%,.1);
			outline: 1px dotted hsla(0,0%,100%,.25);
			outline-offset: -5px;
			-moz-outline-radius: 1px;
		}

		&:not(:disabled):hover {
			background: hsla(0,0%,100%,.2);
		}

		&::-moz-focus-inner {
			border: 0;
		}

		&:disabled {
			opacity: .6;
			cursor: not-allowed;
		}
	}

	& > button,
	& > .mv-button {
		padding: .35em .6em;
		border: 0;
		text-align: center;

		&:first-child {
			margin-left: auto;
		}

		&::before {
			display: inline-block;
			vertical-align: -.1em;
			margin-right: .3em;
			color: white;
			opacity: .5;
			font-weight: normal;
		}

		&:enabled:hover::before {
			opacity: .8;
		}
	}

	.mv-avatar {
		all: unset;
		display: inline-block;
		vertical-align: -.1em;
		max-height: 1em;
	}

	.mv-login {
		&::before {
			content: "🔑 ";
			filter: brightness(160%) grayscale(100%);
		}
	}

	.mv-edit {
		&::before {
			content: "✎";
		}

		[mv-app][mv-mode="edit"] & {
			background: rgba(0,0,0,.6);
			box-shadow: .2em 0 .1em -.1em black inset, -.2em 0 .1em -.1em black inset;

			&:focus {
				background: rgba(0,0,0,.4);
			}
		}
	}

	.mv-save {
		position: relative;

		&::before {
			content: "✓";
		}

		[mv-app][mv-progress="Saving"] & {
			color: hsla(0,0%,100%,.2);

			&::before {
				opacity: .2;
			}

			&::after {
				content: $loader;
				position: absolute;
				top: .5em;
				right: .5em;
				bottom: .5em;
				left: .5em;
			}
		}

		[mv-app].mv-unsaved-changes &,
		[mv-app].mv-unsaved-changes &::before {
			text-shadow: 0 0 .2em hsl(200, 100%, 70%), 0 0 .3em hsl(200, 100%, 70%);
		}

		&:hover {
			background: hsl(180, 50%, 50%);
		}
	}

	.mv-export {
		&::before {
			content: "⬇";
		}
	}

	.mv-import {
		&::before {
			content: "⬆";
		}
	}

	.mv-logout {
		&::before {
			content: "↪";
		}
	}

	.mv-status {
		flex: 1;
		line-height: 2.4;
		padding: 0 .4em;
		margin-top: auto;
		margin-bottom: auto;
	}

	// Displaying mv-bar in small spaces
	&.mv-compact {
		.mv-status > span {
			display: none;
		}
	}

	&.mv-tiny {
		& > button,
		& > .mv-button {
			// Hide text
			width: 1em;
			position: relative;
			overflow: hidden;
			text-indent: -999em;

			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
			}
		}
	}
}

// Essential styles that should be applied even with no .mv-ui
.mv-bar.mv-bar {
	.mv-import {
		position: relative;
		overflow: hidden;
		cursor: auto;

		input[type=file] {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			width: 100%;
			height: 100%;
			font-size: 300px;
			opacity: 0;
		}
	}
}

[mv-app][mv-bar="none"] .mv-bar,
.mv-bar:empty {
	display: none;
}
