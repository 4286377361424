$font-sans: system-ui, 'Helvetica Neue', sans-serif;
$font-mono: Consolas, Monaco, monospace;

@mixin highlight($color, $important: "") {
	box-shadow: 0 0 0 50vmax $color inset #{$important};
}

// Replace letters
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// Encode symbols
@function url-encode($string) {
	$map: (
		"%": "%25",
		"<": "%3C",
		">": "%3E",
		" ": "%20",
		"!": "%21",
		"*": "%2A",
		"'": "%27",
		'"': "%22",
		"(": "%28",
		")": "%29",
		";": "%3B",
		":": "%3A",
		"@": "%40",
		"&": "%26",
		"=": "%3D",
		"+": "%2B",
		"$": "%24",
		",": "%2C",
		"/": "%2F",
		"?": "%3F",
		"#": "%23",
		"[": "%5B",
		"]": "%5D"
	);

	$new: $string;

	@each $search, $replace in $map {
		$new: str-replace($new, $search, $replace);
	}

	@return $new;
}

// Format the SVG as a URL
@function inline-svg($string) {
	@return url('data:image/svg+xml,#{url-encode($string)}');
}

$rubbish-bin: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 125" fill="rgb(200,0,0)">\
	<path d="M65,15.437V8c0-3.86-3.141-7-7-7H42c-3.86,0-7,3.14-7,7v7.437C14.017,16.816,14,21,14,21v10h72V21 C86,21,85.982,16.816,65,15.437z M43,9h14v6.086C54.828,15.032,52.51,15,50,15c-2.51,0-4.828,0.032-7,0.086V9z"/>\
	<path d="M19,37l6,62h50l6-62H19z M35.167,88.995C35.11,88.998,35.053,89,34.998,89c-1.581,0-2.904-1.236-2.993-2.834l-2-36  c-0.092-1.654,1.175-3.07,2.829-3.162c1.663-0.089,3.07,1.175,3.162,2.829l2,36C38.087,87.488,36.821,88.903,35.167,88.995z M53,86\
			c0,1.657-1.343,3-3,3c-1.657,0-3-1.343-3-3V50c0-1.657,1.343-3,3-3c1.657,0,3,1.343,3,3V86z M67.995,86.166  C67.906,87.764,66.583,89,65.003,89c-0.057,0-0.112-0.002-0.169-0.005c-1.654-0.092-2.921-1.507-2.829-3.161l2-36\
			c0.093-1.655,1.533-2.906,3.161-2.829c1.654,0.092,2.921,1.508,2.829,3.162L67.995,86.166z"/>\
	</svg>');
$loader: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 100">\
<style>\
@keyframes grow {\
	33.3% {stroke-width: 50px}\
	from, 66.6%, to { stroke-width: 0}\
}\
\
circle {\
	animation: grow .6s infinite both;\
	fill: white;\
	stroke: white;\
}\
</style>\
<circle cx="50" cy="50" r="22" />\
<circle cx="150" cy="50" r="22" style="animation-delay: .2s" />\
<circle cx="250" cy="50" r="22" style="animation-delay: .4s" />\
</svg>');

[mv-app] {
	--mv-loader: #{$loader};
	--mv-rubbish-bin: #{$rubbish-bin};
}
